import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../../dummyData/images/sutivan_logo.png';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import LanguageComponent from '../../../components/languageComponent/LanguageComponent';
import { useScrollPosition } from '../../../utils/hooks';
import { useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
const Topbar = ({ apartmans }) => {
  const scrolled = useScrollPosition();

  const { lang } = useSelector((store) => store.lang);
  const { t } = useTranslation();

  const [showArrow, setShowArrow] = useState(true);

  const location = useLocation();
  const path = location.pathname;

  const lastSlashIndex = path.lastIndexOf('/');

  const isHomepage = t('routes:home');

  useEffect(() => {
    if (path === isHomepage) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  }, [path]);

  const redirectFunc = (slug) => {
    const newUrlWithoutLastChunk = path.substring(0, lastSlashIndex);
    const apartmansRoute = t('routes:apartmans');
    const newFullUrl = `${apartmansRoute}/${slug}`;
    // history.push(newFullUrl);
    // redirect(newFullUrl);
    return newFullUrl;
  };

  // '/' + `${t('routes:home')}`

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {}, [lang]);

  return (
    <>
      <Navbar expand="md" className={scrolled > 0 ? 'header_scrolled' : 'header'}>
        <div style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', maxWidth: '1440px', width: '100%' }}>
          <Col className="logo-component" xs={2}>
            <Navbar.Brand>
              <Link to={isHomepage}>
                <img className="topbar-image" src={logo} alt="Sutivan logó" />
              </Link>
            </Navbar.Brand>
          </Col>
          <Col xs={8}>
            <Nav className="nav-link">
              {t('links:topbar', { returnObjects: true }).map((item, key) =>
                item.type === 'dropdown' ? (
                  <>
                    <NavDropdown title={<strong>{item.title}</strong>}>
                      {apartmans && apartmans.length > 0
                        ? apartmans.map((item) => (
                            <>
                              <LinkContainer key={key} onClick={() => scrollToTop()} to={redirectFunc(item.slug)}>
                                <NavDropdown.Item>
                                  <strong>{item.title}</strong>
                                </NavDropdown.Item>
                              </LinkContainer>
                              <NavDropdown.Divider />
                            </>
                          ))
                        : null}
                    </NavDropdown>
                  </>
                ) : (
                  <Nav.Link onClick={() => scrollToTop()} key={key} as={Link} className="nav-link" to={item.path}>
                    <strong>{item.title}</strong>
                  </Nav.Link>
                )
              )}
            </Nav>
          </Col>
          <Col className="language-component" xs={2}>
            <LanguageComponent />
          </Col>
        </div>
      </Navbar>
      <div
        className={
          showArrow ? (scrolled ? 'app__header_decor_scrolled' : 'app__header_decor') : 'app__header_decor_hide'
        }
        // className="app__header_decor"
      ></div>
    </>
  );
};

export default Topbar;
