import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectEnglish, selectHungarian, selectCroatian, selectGerman } from './context/langSelector/langSlice';
import i18n from './utils/translater';

// import Apartmans from "./pages/apartmans/Apartmans"
// import BracIsland from "./pages/bracIsland/BracIsland"
// import Turns from "./pages/turns/Turns"
// import Contact from "./pages/contact/Contact"
// import PageNotFound from "./pages/pageNotFound/PageNotFound";

import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Header from './layouts/header/Header';
//import Footer from "./layouts/footer/Footer";
import { useSwtichPath, usePath } from './utils/hooks';
import BracRoutes from './pages/bracIslandPage/components/sidebar/BracRoutes';

import CookieConsent from 'react-cookie-consent';

const Home = lazy(() => import('./pages/homePage/Home'));
const Apartmans = lazy(() => import('./pages/apartmansPage/Apartmans'));
const ApartmanDetailsPage = lazy(() => import('./pages/ApartmanDetailsPage/ApartmanDetailsPage'));
const BracIslandPage = lazy(() => import('./pages/bracIslandPage/BracIslandPage'));
const Turns = lazy(() => import('./pages/turnsPage/Turns'));
const ContactPage = lazy(() => import('./pages/contactPage/ContactPage'));
const PageNotFound = lazy(() => import('./pages/pageNotFound/PageNotFound'));
const Footer = lazy(() => import('./layouts/footer/Footer'));

const TestPage = lazy(() => import('./pages/__tests__/TestPage'));
const PrivacyPage = lazy(() => import('./pages/privacyPage/PrivacyPage'));
const PrivacyPageHU = lazy(() => import('./pages/privacyPage/PrivacyPageHU'));

const App = () => {
  const { t } = useTranslation();
  const { lang } = useSelector((store) => store.lang);
  const dispatch = useDispatch();

  // const path = usePath();
  const redirect = useNavigate();

  const location = useLocation();
  const path = location;

  const switchPath = useSwtichPath(path);

  const checkInitUrl = path.pathname.split('/')[1];
  // console.log('swticxhpath ' + switchPath);
  // console.log('pathname: ', path.pathname);
  // console.log('path key: ', path.key);

  useEffect(() => {
    if (checkInitUrl !== 'en' && checkInitUrl !== 'de' && checkInitUrl !== 'hr') {
      if (lang !== 'hu') {
        dispatch(selectHungarian());
        i18n.changeLanguage('hu');
      }
    } else {
      if (checkInitUrl === 'en' && lang !== 'en') {
        dispatch(selectEnglish());
        i18n.changeLanguage('en');
      }
      if (checkInitUrl === 'de' && lang !== 'de') {
        dispatch(selectGerman());
        i18n.changeLanguage('de');
      }
      if (checkInitUrl === 'hr' && lang !== 'hr') {
        dispatch(selectCroatian());
        i18n.changeLanguage('hr');
      }
    }
  }, []);

  return (
    <>
      <Header />
      <Suspense fallback={<p>Loading...</p>}>
        <Container fluid style={{ minHeight: '80vh' }}>
          <CookieConsent
            location="bottom"
            buttonText={t('privacy.accept_btn')}
            ariaAcceptLabel={t('privacy.accept_btn')}
            cookieName="<cookieName>"
            style={{ background: '#1072A2', justifyContent: 'center' }}
            buttonStyle={{
              color: '#1072A2',
              backgroundColor: '#ffffff',
              fontSize: '16px',
              fontWeight: '700',
              borderRadius: '5px',
              padding: '10px 10px'
            }}
            expires={150}
          >
            <span>{t('privacy.text')}</span>
            <span>
              <a
                style={{
                  color: '#ffffff',
                  fontWeight: '700',
                  marginLeft: '25px'
                }}
                href={t('routes:privacy')}
                // href={'/' + `/${t('routes:privacy')}`}
              >
                {t('privacy.more_informations')}
              </a>
            </span>
          </CookieConsent>
          <Routes>
            {/* BRAC ROUTES START */}
            <Route path={'/' + `${t('routes:sutivan')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:supetar')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:splitska')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:skrip')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:lovrecinaBay')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:pucisca')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:vidovaGora')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:nerezisce')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:bol')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:blacaMonastery')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:milna')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:bobovisca')}`} element={<BracIslandPage />} />
            <Route path={'/' + `${t('routes:bracVideo')}`} element={<BracIslandPage />} />
            <Route path={'/brac-sziget/kerekpartura'} element={<BracIslandPage />} />
            {/* BRAC ROUTES END */}

            <Route path={'/test'} element={<TestPage />} />

            <Route path={'/' + `${t('routes:belvedere')}`} element={<ApartmanDetailsPage />} />
            <Route path={'/' + `${t('routes:boguvilla')}`} element={<ApartmanDetailsPage />} />
            <Route path={'/' + `${t('routes:apartmans')}`} element={<Apartmans />} />
            <Route path={'/' + `${t('routes:turns')}`} element={<Turns />} />
            {/* <Route path={'/' + `${t('routes:privacy')}`} element={<PrivacyPage />} /> */}
            <Route path={'/hr/politika-privatnosti'} element={<PrivacyPage />} />
            <Route path={'/de/datenschutz'} element={<PrivacyPage />} />
            <Route path={'/en/privacy-policy'} element={<PrivacyPage />} />
            <Route path={'/adatvedelem'} element={<PrivacyPageHU />} />
            <Route path={'/' + `${t('routes:contact')}`} element={<ContactPage />} />
            <Route path={'/' + `${t('routes:home')}`} element={<Home />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Container>
        <Footer />
      </Suspense>
    </>
  );
};

export default App;
