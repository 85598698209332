import { createSlice } from '@reduxjs/toolkit';

const lang = localStorage.getItem('lng');

const initialState = {
  lang: lang ? lang : 'hu',
  loading: false,
  error: ''
};

const langSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    selectEnglish: (state) => {
      state.lang = 'en';
      localStorage.setItem('lng', 'en');
    },
    selectHungarian: (state) => {
      state.lang = 'hu';
      localStorage.setItem('lng', 'hu');
    },
    selectGerman: (state) => {
      state.lang = 'de';
      localStorage.setItem('lng', 'de');
    },
    selectCroatian: (state) => {
      state.lang = 'hr';
      localStorage.setItem('lng', 'hr');
    }
  }
});

export const { selectEnglish, selectHungarian, selectGerman, selectCroatian } = langSlice.actions;

export default langSlice.reducer;
