import { useEffect } from 'react';
import i18n from '../../utils/translater';
import { selectEnglish, selectHungarian, selectCroatian, selectGerman } from '../../context/langSelector/langSlice';
import { usePath, useSwtichPath } from '../../utils/hooks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GB, HU, DE, HR } from 'country-flag-icons/react/3x2';
import { useSelector } from 'react-redux';

const LanguageComponent = () => {
  const { lang } = useSelector((store) => store.lang);

  const dispatch = useDispatch();
  const path = usePath();
  const redirect = useNavigate();
  const { t } = useTranslation();

  const switchPath = useSwtichPath(path);

  useEffect(() => {}, [lang]);

  const switchToHu = (lang) => {
    dispatch(selectHungarian());
    i18n.changeLanguage(lang);
    redirect(t(`routes:${switchPath}`, { lng: lang }));
  };

  const switchToEn = (lang) => {
    dispatch(selectEnglish());
    i18n.changeLanguage(lang);
    redirect(t(`routes:${switchPath}`, { lng: lang }));
  };

  const switchToDe = (lang) => {
    dispatch(selectGerman());
    i18n.changeLanguage(lang);
    redirect(t(`routes:${switchPath}`, { lng: lang }));
  };

  const switchToHr = (lang) => {
    dispatch(selectCroatian());
    i18n.changeLanguage(lang);
    redirect(t(`routes:${switchPath}`, { lng: lang }));
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          marginTop: '8px',
          justifyContent: 'center'
        }}
      >
        <HU title="Hungarian" className="flag" onClick={() => switchToHu('hu')} />
        <GB title="English" className="flag" onClick={() => switchToEn('en')} />
        <DE title="Deutsch" className="flag" onClick={() => switchToDe('de')} />
        <HR title="Hrvatski" className="flag" onClick={() => switchToHr('hr')} />
      </div>
    </>
  );
};

export default LanguageComponent;
