import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../../dummyData/images/sutivan_logo.png';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import LanguageComponent from '../../../components/languageComponent/LanguageComponent';
import { useLocation } from 'react-router-dom';

const MobileTopbar = ({ apartmans }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const lastSlashIndex = path.lastIndexOf('/');

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setExpanded(false);
  };

  const redirectFunc = (slug) => {
    const newUrlWithoutLastChunk = path.substring(0, lastSlashIndex);
    const apartmansRoute = t('routes:apartmans');
    const newFullUrl = `${apartmansRoute}/${slug}`;
    // history.push(newFullUrl);
    // redirect(newFullUrl);
    return newFullUrl;
  };

  return (
    <>
      <Navbar expand="lg" className="header-mobile" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img className="topbar-image-mobile" src={logo} alt="Sutivan logó" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="navbarScroll">
            <Nav>
              {t('links:topbar', { returnObjects: true }).map((item, key) =>
                item.type === 'dropdown' ? (
                  <>
                    <NavDropdown
                      //onClick={() => scrollToTop()}
                      title={<strong>{item.title}</strong>}
                    >
                      {apartmans.map((item) => (
                        <>
                          <NavDropdown.Item>
                            <Nav.Link
                              onClick={() => scrollToTop()}
                              key={key}
                              as={Link}
                              className="nav-link"
                              to={redirectFunc(item.slug)}
                            >
                              <strong>{item.title}</strong>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                        </>
                      ))}
                    </NavDropdown>
                    <NavDropdown.Divider
                      style={{
                        maxWidth: '50%',
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                    />
                  </>
                ) : (
                  <Nav.Link
                    key={item.id}
                    as={Link}
                    className="nav-link"
                    to={item.path}
                    onClick={() => {
                      {
                        setExpanded(false);
                        scrollToTop();
                      }
                    }}
                  >
                    <strong>{item.title}</strong>
                    <NavDropdown.Divider style={{ maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto' }} />
                  </Nav.Link>
                )
              )}
              <div className="language-component-mobile">
                <LanguageComponent />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default MobileTopbar;
