import axios from 'axios';
import config from '../config/config';

const API_URL = config.BASE_URL + '/api/v1/public/';

// Fetch data from server
const fetchData = async (url) => {
  try {
    const response = await axios.get(API_URL + url);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Fetch data by slug from server
const fetchDataBySlug = async (fetchURL) => {
  try {
    const response = await axios.get(API_URL + fetchURL);
    return response.data;
  } catch (error) {
    return error;
  }
};

const postData = async (url, payload) => {
  return axios.post(API_URL + url, payload);
};

const publicAPIServices = {
  fetchData,
  fetchDataBySlug,
  postData
  // fetchProductByCategory,
  // fetchProductByID
};

export default publicAPIServices;
