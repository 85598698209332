import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './utils/translater';
import './assets/styles/sutivan.global.css';
import './assets/styles/header.css';
import './assets/styles/footer.css';
import 'react-alice-carousel/lib/alice-carousel.css';


import { BrowserRouter } from 'react-router-dom';

import { store } from './context/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
