import { useEffect } from 'react';
import Topbar from './topbar/Topbar';
import MobileTopbar from './mobileTopbar/MobileTopbar';
import { fetchApartmansData } from '../../context/dataSlices/apartmanDataSlice';
import { useSelector, useDispatch } from 'react-redux';
const Header = () => {
  const { lang } = useSelector((store) => store.lang);
  const dispatch = useDispatch();
  const fetchURL = `apartmans/get-all-data?lang=${lang}`;
  const apartmansData = useSelector((state) => state.apartmans);
  const { data, loading, error, message } = apartmansData;
  useEffect(() => {
    dispatch(fetchApartmansData(fetchURL));
  }, [dispatch, lang]);
  return (
    <>
      {data && data.length > 0 ? (
        <>
          <Topbar apartmans={data} />
          <MobileTopbar apartmans={data} />{' '}
        </>
      ) : null}
    </>
  );
};

export default Header;
