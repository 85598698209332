import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import publicAPIServices from '../../services/publicAPIServices';

const initialState = {
  data: [],
  loading: false,
  error: false,
  success: false,
  message: ''
};

//fetch

export const fetchBracData = createAsyncThunk('bracAllData/fetch', async (url, thunkAPI) => {
  try {
    return await publicAPIServices.fetchData(url);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bracAllDataSlice = createSlice({
  name: 'bracAllData',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBracData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBracData.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.data = action.payload;
      })
      .addCase(fetchBracData.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.message = action.payload;
        state.data = null;
      });
  }
});

export const { reset } = bracAllDataSlice.actions;
export default bracAllDataSlice.reducer;
