import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18n from './translater';

export const useScrollPosition = () => {
  const [isScrolling, setIsScrolling] = useState(0);
  useEffect(() => {
    const updatePosition = () => {
      setIsScrolling(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);
  return isScrolling;
};

export const usePath = () => {
  const location = useLocation();
  const path = location.pathname;
  return path;
};

export const useLang = () => {
  const language = localStorage.getItem('lng');
  return language;
};

export const useColor = () => {
  const color = localStorage.getItem('theme');
  return color;
};

export const useLang2 = () => {
  const { lang } = useSelector((store) => store.lang);
  return lang;
};

export const useSwtichPath = (path) => {
  const routes = i18n.getResourceBundle(i18n.language, 'routes');

  const currentRouteKey = Object.keys(routes).find((key) => routes[key] === path);

  return currentRouteKey;
};
