import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import langReducer from './langSelector/langSlice';
import bracReducer from './dataSlices/bracDataSlice';
import apartmansReducer from './dataSlices/apartmanDataSlice';
import infoReducer from './dataSlices/informationDataSlice';
import bracAllDataReducer from './dataSlices/bracAllDataSlice';
import apartmanOtherDataReducer from "./dataSlices/apartmanOtherDataSlice"

import roomCatsAllDataReducer from "./dataSlices/roomsCats/RoomsCatsAllSlice"
import roomCatsFilteredDataReducer from "./dataSlices/roomsCats/RoomsCatsFiltered"

import roomsFilteredReducer from "./dataSlices/rooms/RoomsFilteredSlice"
import allRoomsDataReducer from "./dataSlices/rooms/roomsAllDataSlice"
import roomsSortedDataReducer from "./dataSlices/rooms/roomsSortedDataSlice"

import turnsReducer from './dataSlices/turnsSlices';
const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const reducer = combineReducers({
  lang: langReducer,
  brac: bracReducer,
  apartmans: apartmansReducer,
  apartmansData:apartmanOtherDataReducer,
  info: infoReducer,
  bracAllData: bracAllDataReducer,
  roomCatsAll:roomCatsAllDataReducer,
  roomCats:roomCatsFilteredDataReducer,
  roomsFiltered:roomsFilteredReducer,
  allRoomsData:allRoomsDataReducer,
  turns:turnsReducer,
  roomsSorted:roomsSortedDataReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
