import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: require('../config/locales/en/en.json'),
      routes: require('../config/locales/en/enRoutes.json'),
      links: require('../config/locales/en/enLinks.json')
    },
    hu: {
      translations: require('../config/locales/hu/hu.json'),
      routes: require('../config/locales/hu/huRoutes.json'),
      links: require('../config/locales/hu/huLinks.json')
    },
    de: {
      translations: require('../config/locales/de/de.json'),
      routes: require('../config/locales/de/deRoutes.json'),
      links: require('../config/locales/de/deLinks.json')
    },
    hr: {
      translations: require('../config/locales/hr/hr.json'),
      routes: require('../config/locales/hr/hrRoutes.json'),
      links: require('../config/locales/hr/hrLinks.json')
    }
  },
  fallbackLng: 'hu',
  debug: false,
  lng: localStorage.getItem('lng') || 'hu',
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'hu', 'de', 'hr'];

export default i18n;
